import React from 'react'

const App = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <h1 className='text-[4vw] font-bold'> React App App </h1>
      </div>
  )
}

export default App